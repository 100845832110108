<template>
  <div>
    <div class="image_warp"
         @mouseleave="mouseover">
      
      <img class="img_warp_Sss"
           :src="imageList[index] ? Array.isArray(imageList[index])?imageList[index][idx2] : imageList[index]:''"
           v-drag
           :style="{
           'top':`${scale*120}px`,
           'left':`${scale*120}px`,
           'right':`${scale*120}px`,
           'bottom':`${scale*120}px`,
           'width':imgWidth?`${imgWidth * scale}px`:''
           }">
    </div>

    <div class="operyion_warp"
         v-if="Object.keys(imageList).length">
      <span @click="refresh">
        <img src="@/assets/checkTestPage/icon-shuaxin@2x.png"
             alt="">
      </span>
      <span @click="xiao">
        <img src="@/assets/checkTestPage/icon-xiao@2x.png"
             alt="">
      </span>
      <span @click="da">
        <img src="@/assets/checkTestPage/icon-da@2x.png"
             alt="">
      </span>
    </div>
    <div class="image_list">
      <!-- <img v-for="(item,idx) in imageList"
           :key="idx"
           @click="showImg(idx)"
           :src="imageList[idx]"
           :class="{'item':true,'isActive':idx == index}"
           alt=""> -->
      <div v-for="(item,idx) in imageList"
           v-if="!imageList.length >0"
           :key="idx">

        <img v-for="(iitem,idx2) in item"
             :key="idx2"
             @click="showImg(idx, idx2)"
             :src="iitem"
             :class="{'item':true,'isActive':idx == index}"
             alt="">

      </div>
      <div v-for="(item,idx) in imageList"
           v-if="imageList.length >0"
           :key="idx">
        <img :key="item"
             @click="showImg(idx,'stop')"
             :src="item"
             v-if="imageList.length !==0"
             :class="{'item':true,'isActive':idx == index}"
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data () {
    return {
      // 计算第几张图片
      index: 0,
      idx2: 0,
      // 计算角度
      curent: 0,
      suoIndex: 1,
      scale: 1,
      imgWidth: 0,
      imgHeight: 0,
      active: false
    }
  },
  props: ['imageList'],
  watch: {
    index () {
      this.curent = 0
      document.getElementsByTagName('img')[0].style.transform = ''
    }
  },
  directives: {
    drag: {
      // 指令的定义
      inserted: function (el) {
        // el.drag();
        // console.log(el);
        //获取元素
        // var dv = document.getElementById("dv");
        let x = 0;
        let y = 0;
        let l = 0;
        let t = 0;
        Vue.prototype.$isDown = false;
        //鼠标按下事件
        el.onmousedown = function (e) {
          e.preventDefault()
          e.stopPropagation()
          //获取x坐标和y坐标
          x = e.clientX;
          y = e.clientY;

          //获取左部和顶部的偏移量
          l = el.offsetLeft;
          t = el.offsetTop;
          //开关打开
          Vue.prototype.$isDown = true;
          //设置样式
          el.style.cursor = "move";
        };
        //鼠标移动
        window.onmousemove = function (e) {
          if (Vue.prototype.$isDown == false) {
            return;
          }
          //获取x和y
          let nx = e.clientX;
          let ny = e.clientY;
          //计算移动后的左偏移量和顶部的偏移量
          let nl = nx - (x - l);
          let nt = ny - (y - t);

          el.style.left = nl + "px";
          el.style.top = nt + "px";
        };
        //鼠标抬起事件
        el.onmouseup = function () {
          //开关关闭
          Vue.prototype.$isDown = false;
          el.style.cursor = "default";
        };
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (document.getElementsByClassName('img_warp_Sss')[0]) {
        this.imgWidth = document.getElementsByClassName('img_warp_Sss')[0].clientWidth
        this.imgHeight = document.getElementsByClassName('img_warp_Sss')[0].clientHeight
      }
    })
    console.log('sssssssss', this.imageList)
  },
  methods: {
    refresh () {
      this.curent += 90
      var oimg = document.getElementsByClassName('img_warp_Sss')[0]
      oimg.style.transform = `rotate(${this.curent}deg)`
    },
    showImg (idx, idx2) {
      console.log('ids', idx2 != 'stop')
      console.log('imageList', this.imageList[idx])
      this.index = idx
      if (idx2 != 'stop') {

        this.idx2 = idx2
      } else {
        this.idx2 = null
      }

    },
    xiao () {
      if (!this.imgWidth) {
        this.imgWidth = document.getElementsByClassName('img_warp_Sss')[0].clientWidth
      }
      this.scale -= 0.2
      if (this.scale <= 0.2) {
        this.scale = 0.2
      }
    },
    da () {
      if (!this.imgWidth) {
        this.imgWidth = document.getElementsByClassName('img_warp_Sss')[0].clientWidth
      }
      this.scale += 0.2
    },
    setWidth () {
    },
    mouseover (el) {
      Vue.prototype.$isDown = false
    }
  }
}
</script>

<style lang="scss" scoped>
// div {
//   ::-webkit-scrollbar {
//     display: none; /* Chrome Safari */
//   }
//   scrollbar-width: none; /* Firefox */
// }
.img_warp {
  width: 100%;
  height: 700px;
  display: flex;
  overflow: auto;

  img {
    width: 700px;
  }
}
.img_selector {
  width: 500px;
  margin: 0 auto;
}
.optration {
  margin-top: 15px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    cursor: pointer;
    margin: 0 5px;
    font-size: 20px;
  }
}

::v-deep .mouse-cover {
  height: 100px !important  ;
}
.image_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
  img {
    width: 100px;
    height: 100px;
    margin: 5px;
    margin-left: 0;
    cursor: pointer;
    border: 1px solid grey;
  }
}
.isActive {
  border: 4px solid #3f9eff !important;
}
.operyion_warp {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3f9eff;
  border-radius: 5px;
  width: 160px;
  height: 44px;
  margin: 10px auto;
  background: #2d2d2d;
  span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
img {
  border-radius: 5px;
}
.image_warp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: auto;
  height: 700px;
  width: 100%;
}
.img_warp_Sss {
  text-align: center;
  transform: rotate(0);
  transition: all 0.3s;
  position: absolute;
  -webkit-user-drag: none;
}
</style>