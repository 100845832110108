<template>
  <div>
    <div class="images"
         v-if="Array.isArray(imageList)">
      <el-image v-for="item in imageList"
                :key="item"
                style="width: 150px; height: 150px;margin-right:10px"
                :src="item"
                :preview-src-list="imageList"></el-image>
    </div>
    <el-table border
              stripe
              highlight-current-row
              :data="tableData"
              style="width: 100%">
      <el-table-column prop="paper_question_no"
                       align="center"
                       label="题目编号" />
      <el-table-column prop="user_answer"
                       align="center"
                       label="用户答案" />

      <el-table-column align="center"
                       label="用户得分">
        <template slot-scope="{row}">
          <span v-if="row.is_ignored == 1">
            题目忽略
          </span>
          <span v-else-if="row.user_score == ''">
            未批改
          </span>
          <span v-else>
            {{row.user_score}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="question_score"
                       align="center"
                       label="题目总分" />
      <el-table-column prop="comment_to_user"
                       align="center"
                       label="答题情况评价" />
      <el-table-column align="center"
                       label="操作">
        <template slot-scope="{row}">
          <el-button type="text"
                     @click="toDetails(row)">查看题目和解答</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination ref="pagination"
                class="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Images from '@/views/checkTestAll/checTestPage/components/image/index.vue'
export default {
  data () {
    return {
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      imageList: []
    }
  },
  components: {
    Images
  },
  methods: {
    initData (page, limit) {
      this.tableLoading = true
      this.$http({
        url: '/api/v1/papers/paper_detail',
        method: 'get',
        params: {
          page, limit,
          user_paper_id: this.$route.query.user_paper_id
        }
      }).then(res => {
        try {
          this.imageList = JSON.parse(res.data.images).map(item => {
            return this.$addHeader(item)
          })

        }
        catch {
          this.imageList = ''
        }
        this.totalElements = res.data.count
        this.tableData = res.data.list
        this.tableLoading = false
      })
    },
    toDetails (row) {
      this.$router.push('/studentCondition/statistics/details?user_paper_detail_id=' + row.user_paper_detail_id)
    }
  }
}
</script>

<style lang="scss" scoped>
.images {
  width: 100%;
  overflow: auto;
  margin: 0 auto;
  margin: 10px 0;
}
.pagination {
  display: none;
}
</style>